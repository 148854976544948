import React, { useEffect, useState } from 'react';

import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  FormFeedback
} from 'reactstrap';

import { handleSetPassword } from '../../pages/ResetPassword/api';

// Formik Validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

// import images
import logo from '../../assets/images/logo1.png';
import { Link, useNavigate } from 'react-router-dom';

function SetPassword({ generateDetails }) {
  const navigate = useNavigate();
  document.title = ' Set Password | DStayZ';

  // Form validation
  const passwordPattern = /^(?!.*[\s])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,16}$/;
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      password: Yup.string().required('Please Enter Your Password').matches(passwordPattern, "Password should be 8 character long with maximum 16 character of one capital one small one digit and one special case without whitespace"),
      confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'Both Passwords must match')
    }),
    onSubmit: async (values) => {
      try {
        await handleSetPassword({
          contact_number: generateDetails?.contact_number?.toString(),
          isEmail: false,
          password: values.password,
          confirmPassword: values.confirmPassword
        })
        navigate("/login")
      } catch (error) {
        console.log(error)
      }
    }
  });

  useEffect(() => {
    document.body.className = 'authentication-bg';
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = '';
    };
  });

  return (
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <div>
              <div className="card">
                <Link to="/" className="mt-2 d-block auth-logo">
                  <img src={logo} alt="" height="40" className="logo logo-dark" />
                </Link>
                <div className="card-body">

                  <div className="text-center mt-2">
                    <h5 className="text-primary">Set Password</h5>
                    <p className="text-muted">Set Password with DStayZ.</p>
                  </div>

                  <div className="p-2 mt-2">
                    <div className="alert alert-success text-center mb-4" role="alert">
                      Enter your OTP!
                    </div>
                    <Form className="form-horizontal" onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}>
                      <div className="mb-3">
                        <Label className="form-label">Enter Password</Label>
                        <Input
                          name="password"
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ''}
                          invalid={
                            !!(validation.touched.password && validation.errors.password)
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Enter Confirm Password</Label>
                        <Input
                          name="confirmPassword"
                          type="password"
                          placeholder="Enter Confirm Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.confirmPassword}
                          invalid={
                            !!(validation.touched.confirmPassword && validation.errors.confirmPassword)
                          }
                        />
                        {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                          <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-3 text-end">
                        <button
                          className="btn btn-primary w-sm waves-effect waves-light"
                          type="submit"
                          disabled={!validation.isValid}
                        >
                          Submit
                        </button>
                      </div>
                    </Form>
                  </div>

                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SetPassword;
