import * as React from "react";
import Modal from "@mui/material/Modal";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DownloadIcon from "@mui/icons-material/Download";
import { getS3BaseUrl } from "../../helpers/string_helper";

export default function SimpleCarousel({ open, onClose, images }) {
  let [indexes, setIndex] = React.useState(0);
  const s3BaseUrl = getS3BaseUrl();
  function photoDisplayInc() {
    if (images?.length - 1 > indexes) {
      setIndex((c) => c + 1);
    }
  }
  function photoDisplayDec() {
    if (indexes > 0) {
      setIndex((c) => c - 1);
    }
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
         
        <div
          style={{
            display: "flex",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "600px",
            backgroundColor: "background.paper",
            boxShadow: 24,
          }}
        >
          <div
            style={{
              position: "absolute",
              left: "-4em",
              cursor: "pointer",
              top: "50%",
            }}
            onClick={photoDisplayDec}
          >
           {indexes > 0 && <ArrowBackIosNewIcon sx={{color:'#ffffff'}} fontSize="large" />}
          </div>
          {["jpg", "jpeg", "png", "gif"].includes(
            images[indexes]?.location?.split(".")?.pop()?.toString()
          ) ? (
            <>
              <img
                height="300px"
                width="600px"
                src={`${s3BaseUrl}${images[indexes]?.location}`}
                alt={images[indexes]?.id}
              />
              <div
                style={{
                  position: "absolute",
                  right: "-4em",
                  cursor: "pointer",
                  borderRadius: "5px 5px",
                  border: "3px solid black",
                  top: "2px",
                  backgroundColor: "white",
                }}
              >
                <a
                  href={`${s3BaseUrl}${images[indexes]?.location}`}
                  target="_blank"
                  download={"name"}
                  rel="noreferrer"
                >
                  <DownloadIcon
                    fontSize="small"
                    sx={{
                      height: "35px",
                      width: "35px",
                    }}
                  />
                </a>
              </div>
            </>
          ) : ["mp4", "avi", "mkv", "mov", "wmv"].includes(
              images[indexes]?.location?.split(".").pop().toString()
            ) ? (
            <video width="750" height="450" controls controlsList="nodownload">
              <source
                src={`${s3BaseUrl}${images[indexes]?.location}`}
                type={`video/${images[indexes]?.location
                  ?.split(".")
                  .pop()
                  .toString()}`}
              />
            </video>
          ) : (
            <div style={{
                backgroundColor:'white',
            }}>
              <iframe
                title="PDF Viewer"
                src={`https://docs.google.com/viewer?url=${s3BaseUrl}${encodeURIComponent(
                  `${images[indexes]?.location}`
                )}&embedded=true`}
                style={{ width: "600px", height: "450px" }}
                width="100%"
                height="100%"
                frameBorder="0"
                scrolling="no"
              />
            </div>
          )}

          <div
            style={{
              position: "absolute",
              right: "-4em",
              cursor: "pointer",
              top: "50%",
            }}
            onClick={photoDisplayInc}
          >
           {images?.length - 1 > indexes && <ArrowForwardIosIcon sx={{color:'#ffffff'}} fontSize="large" />}
          </div>
        </div>
      </Modal>
    </div>
  );
}
