import axiosInstance from "../utils/axios/auth";

const login = async (payload) => await axiosInstance.post('/auth/login', payload);
const loginTokenVerify = async (token) => await axiosInstance.get(`/auth/login?token=${token}`);
const getUser = async () => await axiosInstance.get('/user/me');
const recoverPassword = async (payload) => await axiosInstance.post('/auth/resend-token', payload);
const verifyOtp = async (payload) => await axiosInstance.post('/auth/verify-user', payload);
const forgetPasswordConfirm = async (payload) => await axiosInstance.post('/auth/forgot-password-confirm', payload)
const updateProfile = async (payload) => await axiosInstance.put('/user/profile-update', payload);

export {
    login,
    getUser,
    recoverPassword,
    verifyOtp,
    forgetPasswordConfirm,
    updateProfile,
    loginTokenVerify,
}