import React, { useEffect, useRef, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import EmailIcon from '@mui/icons-material/Email';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { getAllMessages } from '../../api/message';
import { toast } from 'react-toastify';
import moment from 'moment';
import ReactQuill from 'react-quill';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { getS3BaseUrl, ucFirst } from '../../helpers/string_helper';
import { FileUpload } from '@mui/icons-material';
import ViewDocument from '../../components/Common/ViewDocuments';
import { CircularProgress } from '@mui/material';
import NodataFound from '../../components/Common/NoDataFound';
import NoDataIcon from "../../assets/images/no-data.png";
import PaginationComponent from '../../components/Common/PaginationComponent';
import {
  DEFAULT_DATETIME_FORMAT,
  DEFAULT_PER_PAGE_VALUE,
} from '../../common/constant';

export default function Message() {
  const [messageList, setMessageList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const s3BaseUrl = getS3BaseUrl();
  const [viewFile, setViewFile] = useState(null);
  const [paginationMeta, setPaginationMeta] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PER_PAGE_VALUE);
  const [update, setUpdate] = useState(0);

  const paramsObject = useRef({
    page: 1,
    limit: DEFAULT_PER_PAGE_VALUE,
    filter_fields: [],
    filter_inputs: [],
  });
  const fetchMessages = async (params) => {
    try {
      setIsLoading(true);
      const response = (await getAllMessages(params)).data.data;
      setPaginationMeta(response.meta);
      let level = (response.meta.current_page - 1) * response.meta.per_page;
      const resultData = response.data.map((res) => {
        return {
          ...res,
          indexNo: ++level,
        };
      });
      setMessageList(resultData);
    } catch (error) {
      if (error.response?.data?.message)
        toast.error(error.response?.data?.message);
      else toast.error(error?.message);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const paginationModelMethod = async (data) => {
    if (data?.page) {
      paramsObject.current = {
        ...paramsObject.current,
        page: data.page,
        limit: rowsPerPage,
      };
    } else {
      paramsObject.current = {
        ...paramsObject.current,
        page: 1,
        limit: data?.limit,
      };
      setUpdate((prev) => prev + 1);
      setRowsPerPage(data?.limit);
    }
    await fetchMessages(paramsObject.current);
  };

  useEffect(() => {
    fetchMessages(paramsObject.current);
  }, []);

  return (
    <div className='page-content'>
      <Container fluid>
        <div className='complain_ticket'>
          <div className='inner_page_header'>Messages</div>
          <div className='overall_tickets list-inline'>
            {messageList?.length === 0 && isLoading && (
              <div style={{ textAlign: 'center' }}>
                <CircularProgress />
              </div>
            )}
            {messageList?.length === 0 && !isLoading && (
              <div style={{ textAlign: 'center' }}>
                <NodataFound text='No message to show' icon={NoDataIcon} />
              </div>
            )}
            {messageList.map((data) => (
              <div className='single_ticket' key={data.id}>
                <div className='single_ticket_header single_message_header d-flex justify-content-between align-items-center'>
                  <div className='message_id'>
                    <EmailIcon style={{ color: 'white' }} />
                    {data.indexNo}
                    {'. '}
                    {ucFirst(data.message_receiver_property.message.subject)}
                  </div>
                  <div className='message_time d-flex flex-column align-items-baseline '>
                    <span>
                      <AdminPanelSettingsOutlinedIcon />{' '}
                      {data.message_receiver_property.message.sender.name}
                    </span>
                    <span>
                      <CalendarTodayOutlinedIcon />{' '}
                      {moment(
                        data.message_receiver_property.message.created_at,
                      ).format(DEFAULT_DATETIME_FORMAT)}
                    </span>
                  </div>
                </div>
                <div className='single_message'>
                  <ReactQuill
                    value={data.message_receiver_property.message.body}
                    readOnly={true}
                    theme={'bubble'}
                  />
                  <div className='single_ticket_footer d-flex flex-column p-2'>
                    <span>Files:</span>
                    <ul className='list-inline d-flex mb-0 flex-wrap'>
                      {data.message_receiver_property.message.documents.map(
                        (doc, fileIndex) => (
                          <li key={fileIndex}>
                            <div
                              className='img_box cursor-pointer'
                              onClick={() => setViewFile(doc)}>
                              {'pdf' === doc.extension ? (
                                <PictureAsPdfOutlinedIcon />
                              ) : ['jpeg', 'png'].includes(doc.extension) ? (
                                <img
                                  src={
                                    doc?.location
                                      ? `${s3BaseUrl}${doc.location}`
                                      : FileUpload
                                  }
                                  alt='Sender'
                                />
                              ) : (
                                <FileOpenIcon />
                              )}
                            </div>

                            <a
                              href={`${s3BaseUrl}${doc?.location}`}
                              target='_blank'
                              download={doc?.name}
                              rel='noreferrer'>
                              <div className='img_name'>
                                {doc.name}

                                <DownloadForOfflineIcon
                                  style={{
                                    textAlign: 'center',
                                    fontSize: '20px',
                                    cursor: 'pointer',
                                  }}
                                  fontSize={'inherit'}
                                  color='error'
                                />
                              </div>
                            </a>
                          </li>
                        ),
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <ViewDocument
          show={viewFile}
          file={viewFile}
          onCloseClick={() => setViewFile(null)}
        />
        {messageList?.length > 10 && (
          <div>
            <div className='tabsBody'>
              <Row>
                <Col className='col-12'>
                  <Card className='table_card_holder mt-2'>
                    <CardBody>
                      <div className='numbers_pagination_holder'>
                        <PaginationComponent
                          paginationMeta={paginationMeta}
                          handleChangePagination={paginationModelMethod}
                          update={update}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
}
