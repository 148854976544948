import React, { useEffect, useState } from "react";
import { Button, Card, Container, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { getPropertyOwner } from "../../api/owner";
import { useProfile } from "../../components/Hooks/UserHooks";
import NodataFound from "../../components/Common/NoDataFound";
import NoDataIcon from "../../assets/images/no-data.png";
import AddressComponent from "../../components/Common/Address";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { PropertySlider } from "../../components/Common/PropertyPictureSlider";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import NightShelterOutlinedIcon from '@mui/icons-material/NightShelterOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
import { ACTIONS } from "../../common/constant";
import AddEditPropertyRequest from "../../components/NewPropertyRequests/AddEditPropertyRequest";
import ConfirmationModal from "../../components/Common/ConfirmationModal";
import { toast } from "react-toastify";
import { deletePropertyRequest } from "../../api/new-property-request";

export default function NewPropertyRequests() {
  const [propertyRequests, setPropertyRequests] = useState([]);
  const { user } = useProfile();
  const [isAddDrawerOpen, setAddDrawerOpen] = useState(false);
  // const [anchorEl, setAnchorEl] = useState({});
  const [menu, setMenu] = useState([false, false]);
  const [editData, setEditData] = useState(null);
  const [isDeleteConfirm, setDeleteConfirm] = useState({
    status: false,
    id: null,
  });
  const [isLoading, setIsLoading] = useState(false)


  const toggleMenu = (index) => {
    const newMenuStates = [...menu];
    newMenuStates[index] = !newMenuStates[index];
    setMenu(newMenuStates);
  };

  useEffect(() => {
    fetchPropertyOwner();
  }, []);

  const fetchPropertyOwner = async () => {
    setIsLoading(true);
    try {
      const response = (await getPropertyOwner(user?.property_owner?.id)).data.data;
      setPropertyRequests(response.property_owner.user.property_requests);
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  const deletePropertyRequestHandler = async (id) => {
    try {
      const response = await deletePropertyRequest(id);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        return true;
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      if(error.response?.data?.message) toast.error(error.response?.data?.message);
      else toast.error(error.message);
      console.log(error);
    }
    return false;
  };

  // const handleClick = (event, id) => {
  //   setAnchorEl(prevState => ({ ...prevState, [id]: event.target }));
  // };

  // const handleClose = (id) => {
  //   setAnchorEl(prevState => ({ ...prevState, [id]: null }));
  // };

  // const closeAllMenus = () => {
  //   setEditData(null);
  //   setAnchorEl({});
  // };

  const toggleAddModal = (data) => {
    // closeAllMenus(); // Close all open menus
    if (data) setEditData(data);
    else setEditData(null);
    setAddDrawerOpen(!isAddDrawerOpen);
  }

  return (
    <div className="page-content">
      <Container fluid>
        <div className="enlist_page_header d-flex justify-content-between align-items-center mb-3">
          <div className='inner_page_header mb-0'>Your Enlisted Properties</div>
          <Button className='theme_button sign_in_button mx-2' variant="contained" style={{ zIndex: 0 }}
            onClick={()=>setAddDrawerOpen(true)}>
            <AddCircleOutlineOutlinedIcon />&nbsp;
            Add more Property
          </Button>
        </div>
        <div className="enlist_property">
          {(!propertyRequests || propertyRequests?.length === 0) && (
            <div style={{ textAlign: 'center', height: '500px' }}>
              <NodataFound text='No new property request available to show.' icon={NoDataIcon} />
            </div>
          )}
          {propertyRequests?.map((propertyRequest, index) => (
            <Card key={index} className="">
              <div className="appartment_details d-flex">
                <div className="appartment_img">
                  <PropertySlider images={propertyRequest.documents} />
                </div>
                <div className="child_single_item">
                  <div className="d-flex justify-content-between">
                    <h5>{propertyRequest.name}</h5>
                    <div className="d-flex align-items-center">
                      {propertyRequest.status && (
                        <div className={`status_badge m-0 ${propertyRequest.status.toLowerCase()+'_status'}`}>
                          Status: {propertyRequest.status}
                        </div>
                      )}
                      {/* <MoreVertOutlinedIcon
                        id={`basic-button-${index}`}
                        aria-controls={anchorEl[index] ? `basic-menu-${index}` : undefined}
                        aria-haspopup="true"
                        aria-expanded={anchorEl[index] ? 'true' : undefined}
                        onClick={(event) => {
                          // event.preventDefault();
                          handleClick(event, index)
                        }}
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                      />
                      <Menu
                        id={`basic-menu-${index}`}
                        anchorEl={anchorEl[index]}
                        open={Boolean(anchorEl[index])}
                        // onClose={() => handleClose(index)}
                        MenuListProps={{
                          'aria-labelledby': `basic-button-${index}`,
                        }}
                      >
                        <MenuItem onClick={toggleAddModal}>Edit</MenuItem>
                        <MenuItem onClick={() => handleClose(index)}>Delete</MenuItem>
                      </Menu> */}
                      <Dropdown
                        isOpen={menu[index]}
                        toggle={() => toggleMenu(index)}
                        className="d-inline-block"
                      >
                        <DropdownToggle
                          className="btn waves-effect"
                          id="page-header-user-dropdown"
                          tag="button"
                        >
                        <MoreVertOutlinedIcon
                        aria-haspopup="true"
                        style={{ marginLeft: '10px', cursor: 'pointer' }}
                      />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem tag="a" href="#" onClick={(e) => {
                            e.preventDefault()
                            toggleAddModal(propertyRequest);
                          }}>
                            <i className='mdi mdi-pencil-outline font-size-18 align-middle text-muted me-1' />
                            <span> Edit </span>
                          </DropdownItem>

                          <DropdownItem 
                          onClick={
                          () => setDeleteConfirm({ status: true, id: propertyRequest.id })
                          }
                          >
                            <i className='mdi mdi-trash-can-outline font-size-18 align-middle me-1 text-muted' />
                            <span> Delete </span>
                          </DropdownItem>

                        </DropdownMenu>
                      </Dropdown>

                    </div>
                  </div>
                  <ul className="list-inline enlist_prop_details">
                    {propertyRequest.type &&
                      <li>
                        <HomeOutlinedIcon />
                        <strong>Property Request Type:</strong> <span>{propertyRequest?.type}</span>
                      </li>
                    }
                    {propertyRequest?.landmark && (
                      <li>
                        <RoomOutlinedIcon />
                        <strong>Landmark:</strong> <span>{propertyRequest?.landmark}</span>
                      </li>
                    )}
                    {propertyRequest.room_count && (
                      <li>
                        <NightShelterOutlinedIcon />
                        <strong>Number of Rooms:</strong> <span>{propertyRequest?.room_count}</span>
                      </li>
                    )}
                    {propertyRequest.address && (
                      <li>
                        <MapOutlinedIcon />
                        <strong>Address:</strong> <span><AddressComponent address={propertyRequest.address} /></span>
                      </li>
                    )}
                    {propertyRequest.note && (
                      <li>
                        <MapOutlinedIcon />
                        <strong>Additional Info:</strong> <span>{propertyRequest?.note?.description}</span>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </Card>
          ))}
        </div>
      </Container>

      {isAddDrawerOpen &&
        (<AddEditPropertyRequest
          isOpen={isAddDrawerOpen}
          handleClose={toggleAddModal}
          refresh={fetchPropertyOwner}
          editData={editData}
          owner_user_id={user?.id}
          action={editData ? ACTIONS.EDIT : ACTIONS.CREATE}
        />)}

              {/* Delete confirmation modal */}
      {isDeleteConfirm.status &&
        <ConfirmationModal
        action={ACTIONS.DELETE}
        show={isDeleteConfirm.status}
        onCloseClick={() => setDeleteConfirm({ status: false, id: null })}
        onAcceptClick={async () => {
          const isDeleted = await deletePropertyRequestHandler(
            isDeleteConfirm.id,
          );
          if (isDeleted) {
            fetchPropertyOwner();
          }
          setDeleteConfirm({ status: false, id: null });
        }}
        isDisabled={isLoading}
      />}
    </div>
  );
}
