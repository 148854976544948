export const ROLES = {
  TENANT: 'Tenant',
  EMPLOYEE: '',
  SUPER_ADMIN: 'Super Admin',
  OWNER: 'Owner',
  ADMIN: 'Admin',
  PROPERTY_MANAGER: 'Property Manager',
  PROSPECTING_OWNER: 'Prospecting Owner',
};

export const ACTIVE_STATUS = {
  ACTIVE: 'Active',
  IN_ACTIVE: 'Inactive',
};

export const NOTIFICATION_EVENTS = {
  TENANT_ASSIGNED_TO_BED_BY_ADMIN: {
    event: 'tenant_assigned_to_bed_by_admin',
    url: '/owner-dashboard/owner-property-details'
  },
  TENANT_BOOKED_BED_BY_SELF: {
    event: 'tenant_booked_bed_by_self', // TODO: implementation in controller due
    url: '/owner-dashboard/owner-property-details'
  },
  PG_LEAVING_REQUEST_BY_TENANT: {
    event: 'pg_leaving_request_by_tenant',
    url: '/owner-dashboard/owner-property-details'
  },
  TENANT_EVICTED_BY_ADMIN: {
    event: 'tenant_evicted_by_admin',
    // url: '/'
  },
  NEW_PROPERTY_REQUEST_CREATE: {
    event: 'new_property_request_create',
    url: '/'
  },
  NEW_PROPERTY_REQUEST_DELETE: {
    event: 'new_property_request_delete', // TODO: implementation in controller due
    url: '/'
  },
  PROPERTY_ACTIVATE: {
    event: 'property_create',
    url: '/owner-dashboard/owner-property-details'
  },
  PROPERTY_STATUS_UPDATE: {
    event: 'property_status_update',
    url: '/owner-dashboard/owner-property-details'
  },

  // new added events
  NEW_VISIT_REQUEST_CREATE: {
    event: 'new_visit_request_create',
    url: '/owner-dashboard/owner-property-details'
  },
  COMPLAIN_STATUS_CHANGE: {
    event: 'complain_status_change',
    url: '/complain-ticket'
  },
  MESSAGE_RECEIVED: {
    event: 'message_received',
    url: '/message'
  },
  RENT_SLIP_PUBLISHED: {
    event: 'rent_slip_published',
    url: '/owner-dashboard/owner-property-details'
  },
  RENT_PAYMENT_BY_TENANT: {
    event: 'rent_payment_by_tenant',
    url: '/owner-dashboard/owner-property-details'
  },
  PAYOUT_RECEIVED: {
    event: 'payout_received',
    url: '/owner-dashboard/owner-property-details'
  },
  VACANT_REQUEST_STATUS_UPDATE: {
    event: 'vacant_request_status_update',
    url: '/pg-vacant-request'
},
};
