import axiosInstance from "../utils/axios/auth";
import { getParam } from "../helpers/getParam";

const getAllUnreadNotificationCount = () => axiosInstance.get("/notifications/unread-count");
const getAllNotificationList = (param) => axiosInstance.get("/notifications", getParam(param));
const markAsReadNotificationApi = (payload) => axiosInstance.put("/notifications/mark-as-read", payload);

export {
    getAllUnreadNotificationCount,
    getAllNotificationList,
    markAsReadNotificationApi,
};