import React from 'react';
import { Navigate } from 'react-router-dom';
import PagesMaintenance from '../pages/Utility/pages-maintenance';
import PagesComingsoon from '../pages/Utility/pages-comingsoon';
import Pages404 from '../pages/Utility/pages-404';
import Pages500 from '../pages/Utility/pages-500';
import Login from '../pages/Login/index';
import Logout from '../pages/Authentication/Logout';
import Register from '../pages/Authentication/Register';
import Dashboard from '../pages/Dashboard/index';
import OwnerDashboard from '../pages/Dashboard/ownerDashboard';
import OwnerPropertyDetails from '../pages/Dashboard/ownerPropertyDetails';
import ComplainTicket from '../pages/ComplainTicket';
import Notifications from '../pages/Notifications';
import Message from '../pages/Message';
import PayoutHistoryDetails from '../pages/Dashboard/payoutHistoryDetails';

//  // Inner Authentication
import Login1 from '../pages/AuthenticationInner/Login';
import Register1 from '../pages/AuthenticationInner/Register';
import Recoverpw from '../pages/AuthenticationInner/Recoverpw';
import LockScreen from '../pages/AuthenticationInner/auth-lock-screen';

// Profile
// import UserProfile from '../pages/Authentication/user-profile';
import RecoverPassword from '../pages/ResetPassword/RecoverPassword';
import NewPropertyRequests from '../pages/NewPropertyRequests';
// import { components } from 'react-select';

const authRoutes = [
  {
    path: '/owner-dashboard',
    component: <OwnerDashboard />,
  },
  // { path: '/profile', component: <UserProfile /> },
  {
    path: '/owner-dashboard/owner-property-details',
    component: <OwnerPropertyDetails />,
  },
  {
    path: '/complain-ticket',
    component: <ComplainTicket />,
  },
  {
    path: '/notifications',
    component: <Notifications />,
  },
  {
    path: '/message',
    component: <Message />,
  },
  {
    path: '/',
    exact: true,
    component: <Navigate to='/dashboard' />,
  },
  {
    path: '/payout-history-detail',
    component: <PayoutHistoryDetails />
  },
  {
    path: '/payout-history',
    component: <PayoutHistoryDetails />
  },
  {
    path: '*',
    component: <Navigate to='/owner-dashboard' />
  },
  {
    path: '/new-property-requests',
    component: <NewPropertyRequests />,
  },
];

const publicRoutes = [
  {
    path: '/logout',
    component: <Logout />
  },
  {
    path: '/login',
    component: <Login />
  },
  {
    path: '/recover-password',
    component: <RecoverPassword />
  },
  {
    path: '/register',
    component: <Register />
  },

  {
    path: '/pages-maintenance',
    component: <PagesMaintenance />
  },
  {
    path: '/pages-comingsoon',
    component: <PagesComingsoon />
  },
  {
    path: '/pages-404',
    component: <Pages404 />
  },
  {
    path: '/pages-500',
    component: <Pages500 />
  },

  // Authentication Inner
  {
    path: '/pages-login',
    component: <Login1 />
  },
  {
    path: '/pages-register',
    component: <Register1 />
  },
  {
    path: '/page-recoverpw',
    component: <Recoverpw />
  },
  {
    path: '/auth-lock-screen',
    component: <LockScreen />
  },

];

export { publicRoutes, authRoutes };
