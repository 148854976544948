import React from "react";
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';

export default function PayoutHistoryDetails(){
    const [open, setOpen] = React.useState(false);
    // Dummy data array
    const dummyData = [
        { date: '16th June 2024', paymentTenure: '1st to 15th June', payoutAccountNo: '1234567890124567', adminComment: 'PaLorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam dictum dictum finibus.', transactionId: '1234567890124567' }
    ];

    const handleClickOpen = () => {
        setOpen(true);
    };


    const handleClose = () => {
        setOpen(false);
    };

    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };
    const getStatusColor = (status) => {
        return status === "Paid" ? "#04A314" : "Red";
      };
    return (
        <div className="page-content">
            <div className="rent_history_tab rent_slips_tab rent_slips_detail_tab">
                <div className='complain_ticket'>
                    <div className='overall_tickets list-inline'>
                        {dummyData.map((data, index) => (
                            <div className='single_ticket'>
                                <div className='single_ticket_header d-flex justify-content-between align-items-center' onClick={handleClickOpen} key={index} style={{cursor:'pointer'}}>
                                    <div className='ticket_id'>
                                        <ArrowBackOutlinedIcon style={{color: '#1C1B1F', cursor:'pointer'}}/> 
                                        <b>{data.date}</b></div>
                                    <div className="rs_header_right d-flex">
                                        <div className='ticket_id me-3'><b>Total: ₹4590</b></div> 
                                    </div>
                                </div>
                                <div className='single_ticket_body' key={index}>
                                    <div className="d-grid three_grid_cols">
                                        <div className='create_date bordered_block'>
                                            <div className='responsive_word_break'><CalendarTodayOutlinedIcon /> Payment Tenure:</div> <b>{data.paymentTenure}</b>
                                        </div>
                                        <div className='resolved_by bordered_block'>
                                            <div className='responsive_word_break'><SyncAltOutlinedIcon />Transaction Id:</div> <b>{data.transactionId}</b>
                                        </div>
                                        <div className='resolved_by bordered_block'>
                                            <div className='responsive_word_break'><CreditScoreOutlinedIcon />Payout Account No.:</div> <b>{data.payoutAccountNo}</b>
                                        </div>
                                    </div>
                                    <div className='resolved_by admin_comment_border d-flex'>
                                        <div className='responsive_word_break'><PersonOutlinedIcon />Admin Comment:</div> <b>{data.adminComment}</b>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className="ph_inner_detail_listing">
                            <div className='single_ticket'>
                                <div className='single_ticket_header d-flex justify-content-between align-items-center'>
                                    <div className='ticket_id'><b>Vivek Pathak</b></div>
                                    <div className="rs_header_right d-flex">
                                        <div className='ticket_id me-3'><b>Total: ₹4590</b></div> 
                                    </div>
                                </div>
                                <div className='single_ticket_body'>
                                    <div className="d-grid custom_five_grid_cols">
                                        <div className='create_date bordered_block'>
                                            <div className='responsive_word_break'><BusinessOutlinedIcon /> Floor No:</div> <b>1</b>
                                        </div>
                                        <div className='resolved_by bordered_block'>
                                            <div className='responsive_word_break'><HomeWorkOutlinedIcon />Flat No:</div> <b>1</b>
                                        </div>
                                        <div className='resolved_by bordered_block'>
                                            <div className='responsive_word_break'><HomeOutlinedIcon />Room:</div> <b>1</b>
                                        </div>
                                        <div className='resolved_by bordered_block'>
                                            <div className='responsive_word_break'><PaymentsOutlinedIcon />Rent:</div> <b>₹4250</b>
                                        </div>
                                        <div className='resolved_by bordered_block'>
                                            <div className='responsive_word_break'><EmojiObjectsOutlinedIcon />Amount of Electricity:</div> <b>₹425</b>
                                        </div>
                                    </div>
                                    <div className='resolved_by admin_comment_border d-flex'>
                                        <div className='responsive_word_break'><PersonOutlinedIcon />Comment:</div> <b>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam dictum dictum finibus.</b>
                                    </div>
                                </div>
                            </div>
                            <div className='single_ticket'>
                                <div className='single_ticket_header d-flex justify-content-between align-items-center'>
                                    <div className='ticket_id'><b>Vivek Pathak</b></div>
                                    <div className="rs_header_right d-flex">
                                        <div className='ticket_id me-3'><b>Total: ₹4590</b></div> 
                                    </div>
                                </div>
                                <div className='single_ticket_body'>
                                    <div className="d-grid custom_five_grid_cols">
                                        <div className='create_date bordered_block'>
                                            <div className='responsive_word_break'><CalendarTodayOutlinedIcon /> Floor No:</div> <b>1</b>
                                        </div>
                                        <div className='resolved_by bordered_block'>
                                            <div className='responsive_word_break'><SyncAltOutlinedIcon />Flat No:</div> <b>1</b>
                                        </div>
                                        <div className='resolved_by bordered_block'>
                                            <div className='responsive_word_break'><CreditScoreOutlinedIcon />Room:</div> <b>1</b>
                                        </div>
                                        <div className='resolved_by bordered_block'>
                                            <div className='responsive_word_break'><CreditScoreOutlinedIcon />Rent:</div> <b>₹4250</b>
                                        </div>
                                        <div className='resolved_by bordered_block'>
                                            <div className='responsive_word_break'><CreditScoreOutlinedIcon />Amount of Electricity:</div> <b>₹425</b>
                                        </div>
                                    </div>
                                    <div className='resolved_by admin_comment_border d-flex'>
                                        <div className='responsive_word_break'><PersonOutlinedIcon />Comment:</div> <b>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam dictum dictum finibus.</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}