import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Row, Button } from 'reactstrap';
import { useProfile } from "../../Hooks/UserHooks"
import { getS3BaseUrl, handleKeyDown } from "../../../helpers/string_helper";
import { FileUpload } from '@mui/icons-material';
import FileList from "../../Common/FileList";
import { TextField } from "@mui/material";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { updateProfile } from "../../../api/user";
import { toast } from "react-toastify";
import axiosInstance from "../../../utils/axios/auth";
import { storeUserDetails } from "../../../store/auth";
import { logoutUser } from "../../../store/actions";
import { useDispatch } from "react-redux";
import { ROLES } from "../../../constants/variables";

export default function ViewProfileDrawer({
	isOpen,
	toggleDrawer,
	setIsEditMode,
}) {
	const { user } = useProfile()
	const s3BaseUrl = getS3BaseUrl()
	const [isLoading, setIsLoading] = useState(false)
	const dispatch = useDispatch();
	const [isShowBankFrom, setIsShowBankFrom] = useState(false)
	const bankDetailsSchema = {
		account_holder_name: user?.property_owner?.bank_detail?.account_holder_name ?? '',
		account_number: user?.property_owner?.bank_detail?.account_number ?? '',
		bank_name: user?.property_owner?.bank_detail?.bank_name ?? '',
		ifsc: user?.property_owner?.bank_detail?.ifsc ?? '',
	}
	const bankDetailsValition = Yup.object().shape({
		account_holder_name: Yup.string()
			.strict(true)
			.trim('Must not include leading and trailing spaces')
			.max(100, 'Too Long!'),
		account_number: Yup.string()
			.matches(/^\d{9,18}$/, 'Invalid account number'),
		bank_name: Yup.string()
			.strict(true)
			.trim('Must not include leading and trailing spaces')
			.max(100, 'Too Long!'),
		ifsc: Yup.string()
			.strict(true)
			.trim('Must not include leading and trailing spaces')
			.matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, 'Invalid IFSC code'),
	});

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: bankDetailsSchema,
		validationSchema: bankDetailsValition,
		validateOnMount: true,
		onSubmit: async (bankDetail) => {
			setIsLoading(true);
			const payload = {
				property_owner: {
					bank_detail: bankDetail,
				}
			};
			try {
				const response = await updateProfile(payload);
				if (response.status === 200) {
					toast.success(response.data.message);
					// Refreshing user token in redux
					axiosInstance.get('/user/me').then((response) => {
						dispatch(storeUserDetails({ user: response.data.data }))
					}).catch((err) => {
						console.error(err)
						dispatch(logoutUser());
					})
					setIsShowBankFrom(false)
				} else {
					toast.error(response.data.message);
				}
			} catch (error) {
				toast.error(error.response.data.message);
				console.log(error);
			}
			setIsLoading(false);
		},
	});


	const handleClose = () => {
		setIsEditMode && setIsEditMode(false);
		toggleDrawer(false);
	};

	return (
		<Modal isOpen={isOpen} direction="end" className="tenantDetailsDrawer">
			<div className="profileViewer">
				<ModalHeader toggle={handleClose}>
					View Profile Details
				</ModalHeader>
				<ModalBody>
					<div className='tenantDetailsDrawer'>
						<Row className='mb-5'>
							<div className='col-sm-12'>
								<div className='custom-image-upload'>
									{user?.profile_photo && (
										<div
											className="image-placeholder"
										>
											<img
												src={user?.profile_photo ? `${s3BaseUrl}${user?.profile_photo}` : FileUpload}
												alt={user.profile_photo}
											/>
										</div>
									)}
									<div className='upload_picture mx-3'>
										<div className='pro_person_name'>
											{user?.name}
										</div>
										<div className='pro_person_mob'>
											<i className='fas fa-phone-alt me-1' />
											<span className='country_code'>+91</span>
											<span className='pr_person_contact'>
												{user?.contact_number ?? 'N/A'}
											</span>
										</div>
										<div className='pro_person_mob tenantDetailEmail'>
											<span className='pr_person_contact d-flex align-items-baseline'>
												<i className='fas fa-envelope me-1' />
												{user?.email ?? 'N/A'}
											</span>
										</div>
									</div>
								</div>
							</div>
						</Row>
						<Row className='mb-3'>
							<div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
								<div className='tenantDetailTitle'>Occupation :</div>
								<div className='tenantDetailDesc'>
									{user?.property_owner?.occupation ?? 'N/A'}
								</div>
							</div>

							<div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
								<div className='tenantDetailTitle'>Property Status :</div>
								<div className='tenantDetailDesc'>
									{user?.property_owner?.property_status ?? 'N/A'}
								</div>
							</div>
						</Row>

						<Row className='mb-3 row'>
							<div className="col-sm-12 d-flex align-items-start custom_border_bottom pb-3">
								<div className="tenantDetailTitle" style={{ width: '25%' }}>Address:</div>
								<div className="tenantDetailDesc" style={{ width: '65%' }}>{user?.address}</div>
							</div>
						</Row>

						{/* Bank Details */}
						<Row className='mb-3'>
						{user?.property_owner?.bank_detail && !isShowBankFrom && (
							<div className='col-12 mb-3 row'>
								<div className='bankAccountDet'>
									<h5 className='mb-3'>Bank Account Details</h5>
								</div>
								<Row className='mb-3'>
									<div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
										<div className='tenantDetailTitle'>Bank Name :</div>
										<div className='tenantDetailDesc'>
											{user?.property_owner?.bank_detail?.bank_name ?? 'N/A'}
										</div>
									</div>

									<div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
										<div className='tenantDetailTitle'>IFSC :</div>
										<div className='tenantDetailDesc'>
											{user?.property_owner?.bank_detail?.ifsc ?? 'N/A'}
										</div>
									</div>
								</Row>
								<Row className='mb-3'>
									<div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
										<div className='tenantDetailTitle'>Account Name :</div>
										<div className='tenantDetailDesc'>
											{user?.property_owner?.bank_detail?.account_holder_name ?? 'N/A'}
										</div>
									</div>

									<div className='col-sm-6 d-flex align-items-start custom_border_bottom pb-3'>
										<div className='tenantDetailTitle'>Account Number :</div>
										<div className='tenantDetailDesc'>
											{user?.property_owner?.bank_detail?.account_number ?? 'N/A'}
										</div>
									</div>
								</Row>
							</div>
						)}
						
							{isShowBankFrom &&
								<div className='col-sm-12 d-flex align-items-start custom_border_bottom pb-3'>
									<Row>
										{/* Bank Name */}
										<div className='col-sm-6 mb-4'>
											<TextField
												size='small'
												className='form-control'
												type='text'
												name='bank_name'
												label='Bank Name'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values?.bank_name || ''}
												disabled={isLoading}
												error={
													!!(
														formik.touched?.bank_name &&
														formik.errors?.bank_name
													)
												}
												helperText={
													formik.touched?.bank_name &&
														formik.errors?.bank_name
														? formik.errors?.bank_name
														: ''
												}
											/>
										</div>
										{/* IFSC */}
										<div className='col-sm-6 mb-4'>
											<TextField
												size='small'
												className='form-control'
												type='text'
												name='ifsc'
												label='IFSC'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values?.ifsc || ''}
												disabled={isLoading}
												error={
													!!(
														formik.touched?.ifsc &&
														formik.errors?.ifsc
													)
												}
												helperText={
													formik.touched?.ifsc &&
														formik.errors?.ifsc
														? formik.errors?.ifsc
														: ''
												}
											/>
										</div>
										{/* Account Holder Name */}
										<div className='col-sm-6 mb-3'>
											<TextField
												size='small'
												className='form-control'
												type='text'
												name='account_holder_name'
												label='Account Holder Name'
												onBlur={formik.handleBlur}
												value={formik.values?.account_holder_name || ''}
												onChange={formik.handleChange}
												disabled={isLoading}
												error={
													!!(
														formik.touched?.account_holder_name &&
														formik.errors?.account_holder_name
													)
												}
												helperText={
													formik.touched?.account_holder_name &&
														formik.errors?.account_holder_name
														? formik.errors?.account_holder_name
														: ''
												}
											/>
										</div>
										{/* Account Number */}
										<div className='col-sm-6 mb-3'>
											<TextField
												size='small'
												className='form-control'
												type='text'
												name='account_number'
												label='Account Number'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values?.account_number || ''}
												error={
													!!(
														formik.touched?.account_number &&
														formik.errors?.account_number
													)
												}
												helperText={
													formik.touched?.account_number &&
														formik.errors?.account_number
														? formik.errors?.account_number
														: ''
												}
												onWheel={(e) => e.target.blur()}
												disabled={isLoading}
												onKeyDown={(e) => {
													handleKeyDown(e);
												}}
											/>
										</div>
									</Row>
								</div>}

							{user.role.role === ROLES.OWNER &&
								(<div className='col-sm-12 custom_border_bottom pb-3'>
								<Button
									className="yellow_gradient_btn mx-2"
									size="small"
									type={!isShowBankFrom ? 'button' : 'submit'}
									disabled={isLoading}
									onClick={() => {
										if (!isShowBankFrom)
											setIsShowBankFrom(true);
										else {
											formik.handleSubmit();
										}
									}}
								>
									{isShowBankFrom ? 'Save' : user?.property_owner?.bank_detail ? 'Edit Bank Details' : 'Add Bank Details'}
								</Button>
							</div>)}
						</Row>
						<Row className='mb-3'>
							<div className='col-sm-12 custom_border_bottom pb-3'>
								{
									user?.property_owner?.other_documents?.length > 0 && (
										<>
											<h5 className='mb-3'>Attachments</h5>
											<FileList files={user?.property_owner?.other_documents} />
										</>
									)
								}
							</div>
						</Row>
					</div>
				</ModalBody>
			</div>
		</Modal>
	);
}