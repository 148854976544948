import React, { useState, useRef } from "react";
import Slider from 'react-slick';
import { getS3BaseUrl } from "../../helpers/string_helper";
import Appartment from "../../assets/images/appartment.png";

export const PropertySlider = ({ images }) => {
    const s3BaseUrl = getS3BaseUrl();
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const sliderRef1 = useRef(null);
    const sliderRef2 = useRef(null);

    // Update images array with base URL
    const updatedImages = images.map(image => ({
        ...image,
        location: `${s3BaseUrl}${image.location}`
    }));

    // Calculate slidesToShow based on number of images
    const slidesToShow = Math.min(updatedImages.length, 4); // Adjust 4 as needed

    const settingsMain = {
        asNavFor: nav2,
        ref: slider => {
            sliderRef1.current = slider;
            setNav1(slider);
        },
        arrows: false,
        autoplay: false,
        autoplaySpeed: 3000,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true // Optional: for fade effect instead of slide
    };

    const settingsThumbs = {
        asNavFor: nav1,
        ref: slider => {
            sliderRef2.current = slider;
            setNav2(slider);
        },
        slidesToShow: slidesToShow,
        swipeToSlide: true,
        focusOnSelect: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
        infinite: true,
        speed: 500
    };

    // Handle thumbnail click to change main slider
    const handleThumbnailClick = (index) => {
        if (sliderRef1.current) {
            sliderRef1.current.slickGoTo(index);
        }
    };

    return (
        <div className="pro_slider">
            {updatedImages.length === 0 ? (
                <img src={Appartment} alt="No data" style={{ width: '100%', height: '300px', objectFit: 'cover', borderRadius: '3px' }} />
            ) : updatedImages.length === 1 ? (
                <img src={updatedImages[0].location} alt="Single slide" style={{ width: '100%', height: '300px', objectFit: 'cover', borderRadius: '3px' }} />
            ) : (
                <>
                    <Slider {...settingsMain}>
                        {updatedImages.map((image, index) => (
                            <div key={index}>
                                <img src={image.location} alt={`slide-${index}`} style={{ width: '100%', height: '232px', objectFit: 'contain', background: '#ddd' }} />
                            </div>
                        ))}
                    </Slider>
                    <div className="pro_slider_options">
                        <Slider {...settingsThumbs}>
                            {updatedImages.map((image, index) => (
                                <div key={index} onClick={() => handleThumbnailClick(index)}>
                                    <img src={image.location} alt={`thumbnail-${index}`} style={{ width: '100%', height: '60px', objectFit: 'cover' }} />
                                </div>
                            ))}
                        </Slider>
                    </div>
                </>
            )}
        </div>
    );
};
