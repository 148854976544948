import React from "react";
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import { Link } from 'react-router-dom';
import KingBedOutlinedIcon from '@mui/icons-material/KingBedOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import NodataFound from "../../components/Common/NoDataFound";
import { BED_TYPES } from "../../common/constant";
import dayjs from "dayjs";
import NoDataIcon from "../../assets/images/no-data.png";

export default function VacantRequest({ VacantRequestData }) {
    const fetchBedType = (bedCount) => {
        switch (bedCount) {
            case 1:
                return BED_TYPES.SINGLE;
            case 2:
                return BED_TYPES.DOUBLE;
            case 3:
                return BED_TYPES.TRIPLE;
            default:
                return ''
        }
    }

    return (
        <div className="rent_history_tab rent_slips_tab vacant_history_tab">
            <div className='complain_ticket'>
                <div className='overall_tickets list-inline'>
                    {VacantRequestData?.length === 0 && (
                        <div style={{ textAlign: 'center', height: '500px' }}>
                            <NodataFound text='No Pg Vacant Request available to show.' icon={NoDataIcon} />
                        </div>
                    )}
                    {VacantRequestData?.length > 0 && VacantRequestData.map((data, index) => (
                        <div className='single_ticket' key={index}>
                            <Link to='/payout-history-detail'>
                                <div className='single_ticket_header d-flex justify-content-between align-items-center'>
                                    <div className='ticket_id'><b>{data.user?.name}</b></div>
                                </div>
                            </Link>
                            <div className='single_ticket_body'>
                                <div className="d-grid four_grid_cols">
                                    <div className='create_date particular_block_border'>
                                        <div className='responsive_word_break'><KingBedOutlinedIcon />Bed Type:</div> <b>{fetchBedType(data.bed_count)}</b>
                                    </div>
                                    <div className='resolved_by particular_block_border'>
                                        <div className='responsive_word_break'><HomeOutlinedIcon />Room Type:</div> <b>{data.room_type?.name}</b>
                                    </div>
                                    <div className='resolved_by particular_block_border '>
                                        <div className='responsive_word_break'><CalendarTodayOutlinedIcon />Leaving Date:</div> <b>{dayjs(data?.vacant_request?.leaving_date).format('DD-MM-YYYY')}</b>
                                    </div>
                                    <div className='resolved_by particular_block_border'>
                                        <div className='responsive_word_break'><PersonOutlinedIcon />Acknowledgement Status:</div> <b>{data.vacant_request?.acknowledge_status}</b>
                                    </div>
                                </div>
                                <div className='resolved_by admin_comment_border d-flex'>
                                    <div className='responsive_word_break'><PersonOutlinedIcon />Leaving Reason:</div> <b>{data.vacant_request?.note?.description}</b>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}