import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { getS3BaseUrl } from '../../helpers/string_helper';

function ViewDocument(props) {
  const s3BaseUrl = getS3BaseUrl();
  return (
    <Modal
      size='lg'
      isOpen={props?.show}
      centered={true}
      scrollable={true}
      fade={false}
      toggle={props?.onCloseClick}
      backdrop='static'
      fullscreen='md'
      >
      <ModalHeader toggle={props?.onCloseClick}>
        {props?.file?.name}
      </ModalHeader>
      <ModalBody style={{ alignContent: 'center' }}>
        <div style={{ alignContent: 'center' }}>
          {['jpg', 'jpeg', 'png', 'gif'].includes(
            props?.file?.name?.split('.').pop().toString(),
          ) ? (
            <img
              alt=''
              src={`${s3BaseUrl}${props?.file?.location}`}
              style={{ width: '770px', height: '450px' }}
            />
          ) : ['mp4', 'avi', 'mkv', 'mov', 'wmv'].includes(
              props?.file?.name?.split('.').pop().toString(),
            ) ? (
            <video width='750' height='450' controls controlsList='nodownload'>
              <source
                src={`${s3BaseUrl}${props?.file?.location}`}
                type={`video/${props?.file?.name?.split('.').pop().toString()}`}
              />
            </video>
          ) : (
            <iframe
              title='PDF Viewer'
              src={`https://docs.google.com/viewer?url=${encodeURIComponent(
                `${s3BaseUrl}${props?.file?.location}`,
              )}&embedded=true`}
              style={{ width: '770px', height: '450px' }}
              width='100%'
              height='100%'
              frameBorder='0'
              scrolling='no'
            />
          )}
        </div>
      </ModalBody>
    </Modal>
  );
}

ViewDocument.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.bool,
  file: PropTypes.object,
};

export default ViewDocument;
