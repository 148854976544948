import React, { useState, useEffect } from "react";
import NoDataIcon from "../../assets/images/no-data.png";
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import SyncAltOutlinedIcon from '@mui/icons-material/SyncAltOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import { Link } from 'react-router-dom';
import { getPaidPayoutHistory } from "../../api/owner";
import dayjs from "dayjs";
import NodataFound from "../../components/Common/NoDataFound";

export default function PayoutHistory({ propertyId }) {
    const [payoutHistory, setPayoutHistory] = useState([])

    useEffect(() => {
        if (propertyId) {
            fetchAllPaidHistory()
        }
    }, [propertyId])

    const fetchAllPaidHistory = async () => {
        try {
            const response = await getPaidPayoutHistory(propertyId)
            setPayoutHistory(response.data.data)
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div className="rent_history_tab rent_slips_tab">
            <div className='complain_ticket'>
                {payoutHistory?.length === 0 && (
                    <div style={{ textAlign: 'center', height: '500px' }}>
                        <NodataFound text='No payout history available to show.' icon={NoDataIcon} />
                    </div>
                )}
                <div className='overall_tickets list-inline'>
                    {payoutHistory?.length > 0 && payoutHistory.map((data, index) => (
                        <div className='single_ticket' key={index}>
                            <Link to='/payout-history-detail'>
                                <div className='single_ticket_header d-flex justify-content-between align-items-center'>
                                    <div className='ticket_id'><b>{dayjs(data.payment?.payment_date).format('DD-MM-YYYY')}</b></div>
                                    <div className="rs_header_right d-flex">
                                        <div className='ticket_id me-3'><b>Total: ₹{data?.total_payout_amount}</b></div>
                                    </div>
                                </div>
                            </Link>
                            <div className='single_ticket_body' key={index}>
                                <div className="d-grid three_grid_cols">
                                    <div className='create_date bordered_block'>
                                        <div className='responsive_word_break'><CalendarTodayOutlinedIcon />Payment Tenure:</div> <b>{data?.date_range} {data?.month}</b>
                                    </div>
                                    <div className='resolved_by bordered_block'>
                                        <div className='responsive_word_break'><SyncAltOutlinedIcon />Transaction Id:</div> <b>{data?.payment?.transaction_id}</b>
                                    </div>
                                    <div className='resolved_by bordered_block'>
                                        <div className='responsive_word_break'><CreditScoreOutlinedIcon />Payout Account No.:</div> <b>{data?.user?.property_owner?.bank_detail?.account_number}</b>
                                    </div>
                                </div>
                                <div className='resolved_by admin_comment_border d-flex'>
                                    <div className='responsive_word_break'><PersonOutlinedIcon />Admin Comment:</div> <b>{data?.note?.description}</b>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}