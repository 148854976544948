import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

// Icons

import KitchenOutlinedIcon from '@mui/icons-material/KitchenOutlined';
import LocalLaundryServiceOutlinedIcon from '@mui/icons-material/LocalLaundryServiceOutlined';
import CoffeeMakerOutlinedIcon from '@mui/icons-material/CoffeeMakerOutlined';
import ElevatorOutlinedIcon from '@mui/icons-material/ElevatorOutlined';
import NestCamWiredStandOutlinedIcon from '@mui/icons-material/NestCamWiredStandOutlined';
import CellWifiOutlinedIcon from '@mui/icons-material/CellWifiOutlined';
import CleaningServicesOutlinedIcon from '@mui/icons-material/CleaningServicesOutlined';
import ChairAltOutlinedIcon from '@mui/icons-material/ChairAltOutlined';
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import TableBarOutlinedIcon from '@mui/icons-material/TableBarOutlined';
import SanitizerOutlinedIcon from '@mui/icons-material/SanitizerOutlined';
import DoorFrontOutlinedIcon from '@mui/icons-material/DoorFrontOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { selectAmenitiesIcon } from "../../common/amenities_icon";
import { ucFirst } from "../../helpers/string_helper";
import { Autocomplete } from "@mui/material";
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

export default function AddAmenitiesSelector({ handleAddAmenities, selectedAmenities, handleFormikAmenities, defaultValue = undefined, isLoading = false, isDisabled = false }) {
    const [AddedAmenitiesList, setAddedAmenitiesList] = useState([]);
    const [AmenitiesList, setAmenitiesList] = useState([
        {
            name: 'Fridge',
            icon: <KitchenOutlinedIcon />
        },
        {
            name: 'Washing Machine',
            icon: <LocalLaundryServiceOutlinedIcon />
        },
        {
            name: 'Water Purifier',
            icon: <CoffeeMakerOutlinedIcon />
        },
        {
            name: 'Lift',
            icon: <ElevatorOutlinedIcon />
        },
        {
            name: 'CCTV',
            icon: <NestCamWiredStandOutlinedIcon />
        },
        {
            name: 'Wifi',
            icon: <CellWifiOutlinedIcon />
        },
        {
            name: 'Daily Room Cleaning ',
            icon: <CleaningServicesOutlinedIcon />
        },
        {
            name: 'Daily Dustbin Cleaning ',
            icon: <SanitizerOutlinedIcon />
        },
        {
            name: 'Weekly Once Washroom Cleaning',
            icon: <ChairAltOutlinedIcon />
        },
        {
            name: 'Bed',
            icon: <BedOutlinedIcon />
        },
        {
            name: 'Almirah',
            icon: <DoorFrontOutlinedIcon />
        },
        {
            name: 'Center Table',
            icon: <TableBarOutlinedIcon />
        },
    ]);

    useEffect(() => {
        if (selectedAmenities && selectedAmenities?.length > 0) {
            setAddedAmenitiesList(selectedAmenities)
            let amenitiesNames = []
            selectedAmenities?.forEach((am) => {
                amenitiesNames.push(am.name?.toLowerCase())
            })
            const amenitiesList = AmenitiesList.filter((amen) => !amenitiesNames.includes(amen.name?.toLowerCase()))
            setAmenitiesList(amenitiesList)
        }
    }, [selectedAmenities])

    const handleAddAmenity = (itemName) => {
        const isAlreadyAdded = AddedAmenitiesList.some(item => item.name.toLowerCase() === itemName?.toLowerCase());
        if (!isAlreadyAdded) {
            const selectedItem = AmenitiesList.find(item => item.name.toLowerCase() === itemName?.toLowerCase());
            if (selectedItem) {
                setAddedAmenitiesList(prevList => [...prevList, selectedItem]);
                setAmenitiesList(prevList => prevList.filter(item => item.name?.toLowerCase() !== itemName?.toLowerCase()));
            }
        }
    };

    const handleRemoveAmenities = (itemName) => {
        const isAlreadyAdded = AddedAmenitiesList.some(item => item.name?.toLowerCase() === itemName.name?.toLowerCase());
        if (isAlreadyAdded) {
            const selectedItem = AddedAmenitiesList.filter(item => item.name?.toLowerCase() !== itemName.name?.toLowerCase());
            if (selectedItem) {
                const removeItem = AddedAmenitiesList.find(item => item.name?.toLowerCase() === itemName.name?.toLowerCase());
                setAddedAmenitiesList(selectedItem);
                setAmenitiesList(prevList => [...prevList, removeItem]);
            }
        }
        handleFormikAmenities(itemName);
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            const newValue = event.target.value.trim();
            if (newValue !== '') {
                const isAlreadyAdded = AddedAmenitiesList.some(item => {
                    if (item.name?.toLowerCase() === newValue?.toLowerCase()) {
                        return item
                    }
                });

                let updateValue
                const amenitiesList = AmenitiesList.filter((amen) => {
                    if (amen.name?.toLowerCase() === newValue?.toLowerCase()) {
                        updateValue = amen.name
                    } else {
                        return amen
                    }
                })
                setAmenitiesList(amenitiesList)
                if (!isAlreadyAdded) {
                    const newAmenity = {
                        name: updateValue ?? ucFirst(newValue),
                        icon: selectAmenitiesIcon(newValue)
                    };
                    setAddedAmenitiesList(prevList => [...prevList, newAmenity]);
                    event.target.value = null
                }
            }
        }
    };

    const handleAdd = () => {
        handleAddAmenities(AddedAmenitiesList)
    }

    return (
        <Box className="amenityCustomBox">
            <div className="add_amenities_box" style={{
                overflowY: 'auto'
            }}>
                <Autocomplete
                    id="multiple-limit-tags"
                    options={AmenitiesList}
                    disabled={isDisabled}
                    loading={isLoading}
                    defaultValue={defaultValue}
                    autoHighlight
                    size="small"
                    onClick={(e) => e.preventDefault()}
                    getOptionLabel={() => ''}
                    onClose={handleAdd}
                    onMouseLeave={handleAdd}
                    renderOption={(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                            <li {...optionProps} className="position-relative d-flex" key={key}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleAddAmenity(option.name)
                                }}
                            >
                                {option.icon} <div className="amenity_item">{option.name}</div>
                            </li>
                        );
                    }}
                    renderInput={(params) => (
                        <>
                            <ul className="custom_amenties_options d-flex flex-wrap ps-0" style={{ listStyle: 'none' }}>
                                {AddedAmenitiesList.map((item, index) => (
                                    <li className="position-relative d-flex" key={index + 1}>
                                        {item.icon} <div className="amenity_item">{item.name}</div>
                                        <CancelOutlinedIcon className="cross_ico" onClick={() => handleRemoveAmenities(item)} />
                                    </li>
                                ))}
                            </ul>
                            <TextField
                                {...params}
                                label="Select or create amenities"
                                className="d-flex justify-content-between align-items-center autocomplete-icon"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            <KeyboardArrowDownOutlinedIcon />
                                        </React.Fragment>
                                    )
                                }}
                                onKeyPress={handleKeyPress}
                            />
                        </>
                    )}
                />
            </div>
        </Box>
    )
}
