import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { getS3BaseUrl } from "../../helpers/string_helper";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

function AmenitiesSlider({ files }) {
    const [documents, setDocuments] = useState()
    const sliderRef = useRef(null);
    const s3BaseUrl = getS3BaseUrl()

    useEffect(() => {
        if (files?.length > 0) {
            setDocuments(files)
        }
    }, [files])

    const goToPrevSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };

    const goToNextSlide = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div>
            {documents?.length > 0 && (
                <div>

                    <div className="slick-arrow custom-prev" onClick={goToPrevSlide}>
                        <ChevronLeftOutlinedIcon className="cursor-pointer " />
                    </div>
                    <Slider ref={sliderRef} {...settings}>
                        {documents?.length > 0 && documents.map((item, index) => (
                            <div key={index}>
                                <p className='mb-1'>
                                    <a href={`${s3BaseUrl}${item.location}`} target='_blank' download={item?.name} rel="noreferrer">
                                        <DownloadForOfflineIcon
                                            style={{ textAlign: 'center', fontSize: '28px', cursor: 'pointer', color: '#5b73e8' }} fontSize={'inherit'}
                                        />
                                    </a>
                                </p>
                                {['jpg', 'jpeg', 'png', 'gif'].includes(item?.name?.split('.').pop().toString()) ?
                                    <img alt="" src={`${s3BaseUrl}${item?.location}`} width={610} height={354} style={{ borderRadius: '5px', width: '100%' }} /> :

                                    <iframe title="PDF Viewer" src={`https://docs.google.com/viewer?url=${encodeURIComponent(`${s3BaseUrl}${item?.location}`)}&embedded=true`} width={610} height={354} style={{ borderRadius: '5px', width: '100%' }} frameBorder="0" scrolling="no" />
                                }
                                {/* <img src={`${s3BaseUrl}${item?.location}`} alt={item.name} width={610} height={354} style={{ borderRadius: '5px', width: '100%' }} /> */}
                            </div>
                        ))}
                    </Slider>
                    <div className="slick-arrow custom-next" onClick={goToNextSlide}>
                        <ChevronRightOutlinedIcon style={{ cursor: 'pointer' }} />
                    </div>
                </div>
            )}
        </div>
    );
}

export default AmenitiesSlider;
