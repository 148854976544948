import { useSelector } from "react-redux";

const useProfile = () => {
    let { user } = useSelector((state) => {
        return {
            user: state?.AuthLogin?.auth?.user,
        };
    });

    const token = localStorage.getItem("token-owner");

    return { user, token };
};

export { useProfile };