import React from "react";
import BedMatrixBhk from "./bedMatrixBhk";
import { PROPERTY_TYPES } from "../../common/constant";
import BedMatrixHome from "./bedMatrixHome";

function BedMatrixDetails({ property }) {
    return (
        <div className="bedMatrixPg">

            {/* Render Bed matrix Bhk  */}
            {(property?.type === PROPERTY_TYPES.APARTMENT ?
                <BedMatrixBhk propertyDetails={property} /> :
                <BedMatrixHome propertyDetails={property} />
            )}
        </div>
    );
}

export default BedMatrixDetails;