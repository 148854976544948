import React, { useState } from "react";
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import dayjs from "dayjs";
import ViewNote from "../../components/Common/ViewNote";
import NodataFound from "../../components/Common/NoDataFound";
import NoDataIcon from "../../assets/images/no-data.png";

export default function ScheduleVisit({ ScheduleVisitData }) {
    const [notes, setNote] = useState("");
    const [noteView, setNoteView] = useState(false);

    const toggleNote = () => {
        setNoteView(!noteView);
    };

    const getStatusColor = (status) => {
        return status === "Not Visited" ? "#FAA61B" : "#579e55";
    };

    return (
        <div className="rent_history_tab rent_slips_tab vacant_history_tab">
            <div className='complain_ticket'>
                <div className='overall_tickets list-inline'>
                    {(!ScheduleVisitData || ScheduleVisitData?.length === 0) && (
                        <div style={{ textAlign: 'center', height: '500px' }}>
                            <NodataFound text='No Schedule Visit Request available to show.' icon={NoDataIcon} />
                        </div>
                    )}
                    {ScheduleVisitData?.length > 0 && ScheduleVisitData?.map((data, index) => (
                        <div className='single_ticket' key={index}>
                            <div className='single_ticket_header d-flex justify-content-between align-items-center'>
                                <div className='ticket_id'><b>{data.fullname}</b></div>
                                <div className="rs_header_right d-flex">
                                    <div
                                        className="ticket_id me-3"
                                        style={{
                                            borderRight: "1px solid #ddd",
                                            paddingRight: "12px",
                                            paddingLeft: '12px',
                                            color: 'white',
                                            background: getStatusColor(data.status)
                                        }}
                                    >
                                        {data.status}
                                    </div>
                                    <div
                                        className="rent_status"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setNote(data?.note?.description || "");
                                            toggleNote();
                                        }}
                                    >
                                        <VisibilityOutlinedIcon /> View Notes
                                    </div>
                                </div>
                            </div>
                            <div className='single_ticket_body'>
                                <div className="d-grid three_grid_cols schedule_respo_blocks">
                                    <div className='resolved_by particular_block_border date_ico'>
                                        <div className='responsive_word_break'><CalendarTodayOutlinedIcon />Visiting Date:</div> <b>{dayjs(data.visit_datetime).format('DD-MM-YYYY')}</b>
                                    </div>
                                    <div className='create_date particular_block_border'>
                                        <div className='responsive_word_break'><PersonOutlineIcon />Contact Person's Name:</div> <b>{data.care_taker_name}</b>
                                    </div>
                                    <div className='resolved_by particular_block_border'>
                                        <div className='responsive_word_break'><LocalPhoneOutlinedIcon />Contact Person's Number:</div> <b>{data.care_taker_contact_no}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>


                {noteView && (
                    <ViewNote
                        isAddNoteOpen={noteView}
                        isAddNotetoggle={toggleNote}
                        value={notes}
                        add_title={"Note"}
                    />
                )}
            </div>
        </div>
    );
}