import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import withRouter from '../Common/withRouter';

// i18n
import SidebarContent from './SidebarContent';
import logoDark from '../../assets/images/logo.png';
import logoSm from '../../assets/images/logo-sm.png';
import { useProfile } from '../Hooks/UserHooks';
import { ROLES } from '../../constants/variables';

function Sidebar(props) {
  const {user} = useProfile();
  function tToggle() {
    const { body } = document;
    const windowSize = document.documentElement.clientWidth;

    body.classList.toggle('vertical-collpsed');
    body.classList.toggle('sidebar-enable');

    if (windowSize > 991) {
      (body.getAttribute('data-sidebar-size') === 'sm') ? body.setAttribute('data-sidebar-size', 'lg') : body.setAttribute('data-sidebar-size', 'sm');
    }
  }

  useEffect(() => {
    // var verticalOverlay = document.getElementsByClassName("vertical-overlay");
    if (document.body.classList.contains("vertical-collpsed") && document.body.classList.contains("sidebar-enable")) {
      // verticalOverlay[0].addEventListener("click", function () {
        document.body.classList.remove("vertical-collpsed");
        document.body.classList.remove("sidebar-enable");

      }
    // }
  });

  return (
    <div className="vertical-menu">
      <div className="navbar-brand-box">
        <Link to={user?.role?.role === ROLES.OWNER ? '/' : '/new-property-requests'} className="logo logo-dark">
          <span className="logo-sm">
            <img src={logoSm} alt="" height="22" />
          </span>
          <span className="logo-lg">
            <img src={logoDark} alt="" />
          </span>
        </Link>

        <Link to={user?.role?.role === ROLES.OWNER ? '/' : '/new-property-requests'} className="logo logo-light">
          <span className="logo-sm">
            <img src={logoDark} alt="" height="22" />
          </span>
          <span className="logo-lg">
            <img src={logoDark} alt="" height="20" />
          </span>
        </Link>
      </div>
      <button
        onClick={() => {
          tToggle();
        }}
        type="button"
        className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
      >
        <i className="fa fa-fw fa-bars" />
      </button>
      <div className="sidebar-menu-scroll">
        {props.type !== 'condensed' ? <SidebarContent /> : <SidebarContent />}
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = (state) => ({
  layout: state.Layout,
});
export default connect(
  mapStatetoProps,
  {},
)(withRouter(withTranslation()(Sidebar)));
