import { Pagination, Stack, Autocomplete, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import { DEFAULT_PER_PAGE_VALUE, PER_PAGE } from "../../common/constant";

const PaginationComponent = ({ handleChangePagination, paginationMeta, update }) => {
    const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE_VALUE)

    useEffect(() => {
        if (paginationMeta) {
            setPerPage(paginationMeta.per_page)
        }
    }, [paginationMeta])

    const handlePagination = (event, value) => {
        handleChangePagination({ page: value })
    }

    return (
        <>
            <div className="d-flex justify-content-start number_holder mr-20">
                <span className="pe-2">Showing</span>
                <Autocomplete
                    value={paginationMeta?.total<perPage?.toString()?paginationMeta?.total.toString():perPage?.toString()}
                    onChange={(event, newValue) => {
                        handleChangePagination({ limit: newValue })
                    }}
                    id="controllable-states-demo"
                    isOptionEqualToValue={(option, value) => option?.toString() === value }
                    getOptionLabel={(option) => option?.toString()}
                    options={PER_PAGE}
                    disableClearable={true}
                    renderInput={(params) => <TextField {...params} />}
                />
                <span>of</span>
                <p className="d-flex ps-2 mb-0">{paginationMeta?.total}</p>
            </div>
            <div className="d-flex justify-content-end ml-12" key={update}>
                <Stack spacing={2}>
                    <Pagination count={paginationMeta?.last_page} shape="rounded" variant="outlined" showFirstButton showLastButton onChange={handlePagination} />
                </Stack>
            </div>
        </>
    )
}

export default PaginationComponent